<template>
  <Tile :title="title">
    <b-card body-class="p-2" class="p-0">
      <b-card-body class="p-0">
        <b-container v-if="data" fluid class="px-0">
          <TileRow v-for="entry in data" :key="entry.id">
            <b-row>
              <b-col cols="3">
                <span>ID: {{ entry.id }} <b-badge v-if="entry.inactive" class="ml-2" variant="primary">Inaktiv</b-badge>
                </span>
              </b-col>
              <b-col cols="6" class="text-center">
                <span>{{ entry.command_win }}</span>
              </b-col>
              <b-col cols="auto" class="ml-auto text-right">
                <b-icon-eject-fill v-if="entry.type === 'install'" :id="'tt-iconInstall-' + entry.id"
                                   :variant="variantSuccess" flip-v/>
                <b-icon-eject-fill v-else-if="entry.type === 'deinstall'" :id="'tt-iconDeinstall-' + entry.id"
                                   :variant="variantDanger"/>
                <span v-else>Sonstiges</span>
                <b-icon-pencil-square :id="'tt-editCommand-' + entry.id" class="clickable ml-2"
                                      @click="editCommand(entry)"/>
                <b-tooltip v-if="entry.type === 'install'" :target="'tt-iconInstall-' + entry.id" triggers="hover">
                  Installation
                </b-tooltip>
                <b-tooltip v-else-if="entry.type === 'deinstall'" :target="'tt-iconDeinstall-' + entry.id"
                           triggers="hover">
                  Deinstallation
                </b-tooltip>
                <b-tooltip :target="'tt-editCommand-' + entry.id" triggers="hover">Bearbeiten</b-tooltip>
              </b-col>
            </b-row>
          </TileRow>
        </b-container>
        <b-container v-else class="text-center">
          <TileRow>
            <b-row>
              <b-col>
                <span class="no-data">Keine Commands vorhanden</span>
              </b-col>
            </b-row>
          </TileRow>
        </b-container>
      </b-card-body>
    </b-card>
  </Tile>
</template>

<script>
import {mapGetters} from "vuex";
import Tile from "@/components/details/Tile.vue";
import TileRow from "@/components/fg-software/TileRow.vue";

export default {
  name: "TileCommands",
  props: ['title', 'data'],
  components: {
    Tile,
    TileRow
  },
  methods: {
    editCommand(data) {
      data.task = 'command'
      this.$emit('edit-command', data)
    }
  },
  computed: {
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') {
        return 'success-deuteranopia'
      }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') {
        return 'danger-deuteranopia'
      }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Tiles';
</style>
