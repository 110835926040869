<template>
  <Tile :title="title">
    <b-card body-class="p-2" class="p-0">
      <b-card-body class="p-0 py-2">
        <b-row v-for="(value, key) in groupedData" :key="key">
          <b-col class="text-left text-bold">
            {{ key }}
            <span v-if="key === 'Installationszeit'" class="text-secondary small">
              in HH:mm:ss
            </span>
          </b-col>
          <b-col class="text-right">
            <span>
              <span v-if="value">{{ value }}</span>
              <span v-else>-</span>
            </span>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </Tile>
</template>

<script>
import Tile from "@/components/details/Tile.vue";

export default {
  name: "TileSoftware",
  props: ['title', 'data'],
  components: {
    Tile
  },
  computed: {
    groupedData() {
      if(this.data) {
        return {
          'Status': this.data['active'] ? 'Aktiv' : 'Nicht aktiv',
          'Searchstring': this.data['search'],
          'Installationszeit': this.data['estimate_install_time']
        }
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Tiles';
</style>
