<template>
  <b-card-body class="card-expand text-white pt-3 px-3 pb-2">
    <b-container fluid class="p-0">
      <b-row class="mb-1">
        <b-col>
          <TileSoftware title="Allgemein" class="mb-3" :data="software"/>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col>
          <TileCommands title="Commands" class="mb-3" :data="software.commands" @edit-command="editCommandSync"/>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col>
          <TileSyncs title="Syncs" class="mb-3" :data="software.syncs" @edit-sync="editCommandSync"/>
        </b-col>
      </b-row>
    </b-container>
  </b-card-body>
</template>

<script>
import TileSyncs from "@/components/fg-software/software/details/TileSyncs.vue";
import TileCommands from "@/components/fg-software/software/details/TileCommands.vue";
import TileSoftware from "@/components/fg-software/software/details/TileSoftware.vue";

export default {
  name: "SoftwareDetails",
  props: ['software'],
  components: {
    TileSyncs,
    TileCommands,
    TileSoftware
  },
  methods: {
    editCommandSync(data) {
      this.$emit('edit-command-sync', data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/details/Details';
</style>
