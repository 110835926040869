<template>
  <b-card body-class="p-0 mb-1" header-tag="header" :header-class="['card-row rounded']">
    <template #header>
      <b-container fluid class="px-1 py-2">
       <slot/>
      </b-container>
    </template>
  </b-card>
</template>

<script>
export default {
  name: "TileRow"
}
</script>

<style lang="scss" scoped>
</style>
