<template>
  <b-card body-class="p-0 mb-2" header-tag="header" :header-class="['card-row', {rounded: !this.expand}]">
    <template #header>
      <b-container fluid class="px-1 py-2">
        <b-row :class="{clickable: license.software}" @click.self="expand = !expand">
          <b-col cols="4" :class="{clickable: license.software}" @click.self="expand = !expand">
           {{ license.displayname }}
            <b-icon-caret-down-fill v-if="license.software" :flip-v="expand" @click="expand = !expand"/>
          </b-col>
          <b-col cols="6" :class="['ml-auto', {clickable: license.software}]" @click.self="expand = !expand">
            <BadgeCounterSoftware :software="license"/>
          </b-col>
          <b-col cols="auto" class="clickable" @click.self="expand = !expand">
            <b-link :to="{name: 'FGLicenses', query: { software: license.displayname }}" target="_blank">
              <b-icon-box-arrow-up-right :id="'tt-openSoftware' + license.id" :variant="iconVariant"
                                         class="clickable mr-2"/>
            </b-link>
            <b-icon-pencil-square :id="'tt-editSoftware' + license.id" class="clickable mr-2"
                                  @click="editSoftware(license)"/>
            <b-icon-plus-circle :id="'tt-addCommandSync-' + license.id" class="clickable"
                                @click="addCommandSync(license)"/>
            <b-tooltip :target="'tt-addCommandSync-' + license.id" triggers="hover">Commands/Syncs hinzufügen</b-tooltip>
            <b-tooltip :target="'tt-editSoftware' + license.id" triggers="hover">Bearbeiten</b-tooltip>
            <b-tooltip :target="'tt-openSoftware' + license.id" triggers="hover">Lizenzübersicht öffnen</b-tooltip>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <TransitionExpand>
      <SoftwareDetails v-if="expand && license.software" :software="license.software" @refresh="refresh"
                       @edit-command-sync="editCommandSync"/>
    </TransitionExpand>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import TransitionExpand from "@/components/utility/TransitionExpand";
import SoftwareDetails from "@/components/fg-software/software/SoftwareDetails.vue";
import BadgeCounterSoftware from "@/components/fg-software/badgeCounter/BadgeCounterSoftware";

export default {
  name: "SoftwareRow",
  props: ['license', 'groupId'],
  components: {
    TransitionExpand,
    SoftwareDetails,
    BadgeCounterSoftware
  },
  data() {
    return {
      expand: this.extended
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    addCommandSync(data) {
      this.$emit('add-command-sync', { softwareId: data.id})
    },
    editSoftware(data) {
      data.groupId = this.groupId
      this.$emit('edit-software', data)
    },
    editCommandSync(data) {
      data.softwareId = this.license.id
      this.$emit('edit-command-sync', data)
    },
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    iconVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'white'
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
