<template>
  <div>
    <b-row>
      <b-col cols="2"/>
      <b-col cols="8" style="font-size: 14px">
        <SubHeader class="mb-2">
          <GroupFilter class="mt-2" groupContent="software" @search-changed="setSearch"
                       @filter-options="setFilterOptions" @add-group="addGroup" @add-group-content="addSoftware"/>
        </SubHeader>
        <div v-if="groupsFiltered">
          <div v-for="group in groupsFiltered" :key="group.id" class="mt-3">
            <Group :group="group" @add-group-content="addSoftware" @edit-group="editGroup">
              <template v-slot:groupInfo>
                <b-row v-for="(value, key) in groupInfo(group)" :key="key">
                  <b-col class="text-left">
                    {{ key }}
                  </b-col>
                  <b-col class="text-right">
                    <span v-if="key === 'Lizenzportal'">
                      <a v-if="value" :href="value" target="_blank">{{ value | url }}</a>
                      <span v-else>-</span>
                    </span>
                    <span v-else>
                      <span v-if="value">{{ value }}</span>
                      <span v-else>-</span>
                    </span>
                  </b-col>
                </b-row>
              </template>
              <template v-slot:content>
                <div v-if="group.licenses.length > 0">
                  <div v-for="license in group.licenses" :key="license.id">
                    <SoftwareRow :license="license" :groupId="group.id" @edit-software="editSoftware"
                                 @add-command-sync="addCommandSync" @edit-command-sync="editCommandSync"/>
                  </div>
                </div>
                <b-row v-else cols="1">
                  <b-col>
                    <h6 class="text-secondary text-center">Keine Software vorhanden</h6>
                  </b-col>
                </b-row>
              </template>
            </Group>
          </div>
        </div>
        <b-row v-else cols="1">
          <b-col>
            <h5 class="text-secondary text-center mt-5">Keine Daten vorhanden</h5>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="2"/>
    </b-row>
    <LicenseGroupModal :method="method" :data="groupData" @refresh="refresh"/>
    <SoftwareModal :method="method" :data="softwareData" :groupId="groupId" @refresh="refresh"/>
    <CommandSyncModal :method="method" :data="commandSyncData" @refresh="refresh"/>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import {mapActions} from "vuex";
import {postRequest} from "@/modules/requests";
import SubHeader from "@/components/structure/SubHeader";
import Group from "@/components/fg-software/Group.vue";
import ScrollToTop from "@/components/utility/ScrollToTop";
import SoftwareRow from "@/components/fg-software/software/SoftwareRow";
import GroupFilter from "@/components/fg-software/GroupFilter.vue";
import SoftwareModal from "@/components/fg-software/software/modals/SoftwareModal";
import CommandSyncModal from "@/components/fg-software/software/modals/CommandSyncModal";
import LicenseGroupModal from "@/components/fg-software/software/modals/LicenseGroupModal";

export default {
  name: "SoftwareManage",
  components: {
    Group,
    SubHeader,
    ScrollToTop,
    SoftwareRow,
    SoftwareModal,
    GroupFilter,
    CommandSyncModal,
    LicenseGroupModal
  },
  data() {
    return {
      search: {
        groupSearch: '',
        contentSearch: ''
      },
      groups: [],
      method: null,
      filterOptions: null,
      groupId: null,
      groupData: null,
      softwareData: null,
      commandSyncData: null
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    setSearch(data) {
      this.search = data
    },
    setFilterOptions(data) {
      this.filterOptions = data
      this.refresh()
    },
    addGroup() {
      this.method = 'add'
      this.$bvModal.show('licenseGroupModal')
    },
    addCommandSync(data) {
      this.method = 'add'
      this.commandSyncData = data
      this.$bvModal.show('commandSyncModal')
    },
    addSoftware(data) {
      this.method = 'add'
      this.groupId = data
      this.$bvModal.show('softwareModal')
    },
    editGroup(data) {
      this.method = 'edit'
      this.groupData = data
      this.$bvModal.show('licenseGroupModal')
    },
    editSoftware(data) {
      this.method = 'edit'
      this.softwareData = data
      this.$bvModal.show('softwareModal')
    },
    editCommandSync(data) {
      this.method = 'edit'
      this.commandSyncData = data
      this.$bvModal.show('commandSyncModal')
    },
    groupInfo(group) {
      if(group) {
        return {
          'Status': !group['inactive'] ? 'Aktiv' : 'Nicht aktiv',
          'Lizenzportal': group['license_portal'],
          'Lizenzserver': group['license_server'],
          'Lizenzart': group['license_method'],
          'Lizenzabfrage': group['license_manage'],
          'Notiz': group['note']
        }
      }
      return null
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getLicenses()
      this.setLoadingState(false)
    },
    async loadData() {
      this.setLoadingState(true)
      await this.getLicenses()
      await window.scrollTo(0, 0)
      this.setLoadingState(false)
    },
    async getLicenses() {
      let data
      if(!this.filterOptions) {
        data = {
          inactive: false
        }
      } else {
        data = this.filterOptions
      }
      this.groups = []
      await postRequest('licenses/fg/all', data, this, null)
          .then((response) => {
            this.groups = response.data.groups
          })
    }
  },
  computed: {
    date() {
      return dayjs().format('DD.MM.YYYY')
    },
    groupsFiltered() {
      let groupList = _.filter(this.groups, (group) => {
        return group.displayname.toLowerCase().includes(this.search.groupSearch.toLowerCase())
      })
      return _.filter(groupList, (group) => {
        if(group.licenses.length > 0) {
          return _.filter(group.licenses, (license) => {
            return license.displayname.toLowerCase().includes(this.search.contentSearch.toLowerCase())
          }).length > 0
        }
      })
    }
  },
  created() {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
</style>
