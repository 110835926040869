<template>
  <Tile :title="title">
    <b-card body-class="p-2" class="p-0">
      <b-card-body class="p-0">
        <b-container v-if="data" fluid class="px-0">
          <TileRow v-for="entry in data" :key="entry.id">
            <b-row>
              <b-col cols="3">
                <span>ID: {{ entry.id }} <b-badge v-if="entry.inactive" class="ml-2" variant="primary">Inaktiv</b-badge>
                </span>
              </b-col>
              <b-col cols="6" class="text-center">
                <span>{{ entry.file_name }}</span>
              </b-col>
              <b-col cols="auto" class="ml-auto text-right">
                <span>{{ entry.size_packed / 1000 | float_de }} GB</span>
                <b-icon-pencil-square :id="'tt-editSync-' + entry.id" class="clickable ml-2"
                                      @click="editSync(entry)"/>
                <b-tooltip :target="'tt-editSync-' + entry.id" triggers="hover">Bearbeiten</b-tooltip>
              </b-col>
            </b-row>
          </TileRow>
        </b-container>
        <b-container v-else class="text-center">
          <span class="no-data">Keine Syncs vorhanden</span>
        </b-container>
      </b-card-body>
    </b-card>
  </Tile>
</template>

<script>
import Tile from "@/components/details/Tile.vue";
import TileRow from "@/components/fg-software/TileRow.vue";

export default {
  name: "TileSyncs",
  props: ['title', 'data'],
  components: {
    Tile,
    TileRow
  },
  methods: {
    editSync(data) {
      data.task = 'sync'
      this.$emit('edit-sync', data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Tiles';
</style>
